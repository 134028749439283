import React, {useCallback, useState} from 'react';
import Plus from "assets/images/Plus.svg";
import ModalAddMcc from "../../components/Modal/ModalAddMcc";
import {SelectColumnFilter} from "../../components/Table/SelectedColumnFilter";
import {useSelector} from "react-redux";
import {getTableData} from "reduxStore/reducers/tableData";
import {Roles} from "../../common/RoleFormatter";
import {DDMMYYYYFormat, FormatterDateFromTo, YYYYMMDDFormat} from "../../utils/date";
import {useTranslation} from "react-i18next";
import {UseGetAssignedMccApi, UseGetAvailableMcc} from "../../redux/api/GoogleMccApi";
import {Load} from "../../components/load/Load";
import MccTables from "./MccTables";
import AttentiveIcon from "assets/images/attentionIcon.svg?svgr";

export const alarmLimit = 5;
const MCCGoogleAds = () => {
  const [showAddMccModal, setShowAddMccModal] = useState(false);
  const [showFree, setShowFree] = useState(true);
  const {sidebarInfo} = useSelector(getTableData) || 0;
  const {right} = useSelector(state => state.auth);
  const {t} = useTranslation();
  const [getAssignedList, {data: assignedMcc, isLoading: isAssignedLoading}] = UseGetAssignedMccApi();
  const {data: availableMcc, isLoading: isLoadingAvailableMcc} = UseGetAvailableMcc();


  const columnsAssigned = [
    {
      Header: t("clientMccId"),
      accessor: "mccId",
      Filter: SelectColumnFilter,
      filter: 'equals',
      disableSortBy: true,
    },
    {
      Header: t("middleMccId"),
      accessor: "middleMssId",
      Filter: SelectColumnFilter,
      filter: 'equals',
      disableSortBy: true,
    },
    {
      Header: t("clientName"),
      accessor: "clientName",
      Filter: SelectColumnFilter,
      filter: 'equals',
      disableSortBy: true,
    },
    {
      Header: t("CCEmail"),
      accessor: "clientEmail",
      Filter: SelectColumnFilter,
      filter: 'equals',
      disableSortBy: true,
    },
    {
      Header: t("CCPLActive"),
      accessor: "payLineName",
      Filter: SelectColumnFilter,
      filter: 'equals',
      disableSortBy: true,
    },
    {
      Header: t("creationMCCDate"),
      accessor: "assignedDate",
      Filter: SelectColumnFilter,
      filter: 'equals',
      Cell: ({row}) => <div>{FormatterDateFromTo(row.original.assignedDate, YYYYMMDDFormat, DDMMYYYYFormat)}</div>,
      disableSortBy: true,
    }
  ];
  const columnsAvailable = [
    {
      Header: t("middleMccId"),
      accessor: "middleMssId",
      Filter: SelectColumnFilter,
      filter: 'equals',
      disableSortBy: true,
    },
    {
      Header: t("clientMccId"),
      accessor: "mccId",
      Filter: SelectColumnFilter,
      filter: 'equals',
      disableSortBy: true,
    },
  ];

  const onAssignedTabClick = useCallback(() => {
    getAssignedList()
    setShowFree(false)
  }, [])

  if (isLoadingAvailableMcc || isAssignedLoading) {
    return <Load/>
  }

  return (
    <div>
      {showAddMccModal && <ModalAddMcc show={showAddMccModal} closeCallback={() => setShowAddMccModal(false)}/>}
      <h1 className={"pb-3"}>{"MCC Google Ads"}</h1>
      <div className={"d-flex my-4"}>
        {(right === Roles.ROLE_ADMIN || right === Roles.ROLE_HEAD)
          && <button className={"btn btn-primary d-flex align-items-center mr-0 mr-md-4"}
                     onClick={() => setShowAddMccModal(true)}
          >
            <img src={Plus} alt={"add free Mcc"} className={"pr-2"}/>
            {t("addFreeMCC")}
          </button>}
        <div className="d-flex tab-header__container ">
          <div
            className={`tab-header__item px-3 cursor-pointer d-flex align-items-center ${showFree ? "active" : ""}`}
            onClick={() => setShowFree(true)}
          >
            {sidebarInfo?.freeMcc?.filter(item => item <= alarmLimit)?.length ?
              <AttentiveIcon className={"d-block mr-1"} width={24} height={24} fill={"#FF6600"}/> : null}
            {t("freeMCC")}
          </div>
          <div
            className={`tab-header__item px-3 cursor-pointer d-flex align-items-center ${!showFree ? "active" : ""}`}
            onClick={onAssignedTabClick}
          >{t("occupiedMCC")}</div>
        </div>
      </div>
      <div>

        {showFree ?
          <MccTables availableMcc={availableMcc} columns={columnsAvailable} type={"available"}/> :
          <MccTables availableMcc={assignedMcc} columns={columnsAssigned}/>
        }

      </div>
    </div>
  )
}

export default MCCGoogleAds